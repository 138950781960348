import { Button, Input } from 'antd'
import moment from 'moment'
import { formatAmountOrigin } from 'utils/formatInfoNumbers'
import { ModalContent, WrapperAccount } from './styled'

const ModalConfirm = ({
  title,
  dataModal,
  onConfirm,
  loading,
}: {
  title: string
  dataModal: any
  onConfirm: (params) => Promise<void>
  loading: boolean
}) => {
  return (
    <ModalContent>
      <WrapperAccount>
        <div className="title">{title}</div>
        <div className="flex flex-col gap-3">
          <div className="flex sm:flex-col flex-row gap-3">
            <p className="text-sm text-[#BDCADB]">Staked</p>

            <div className="flex items-center gap-3">
              <p className="text-[#F0F6FF] min-w-[80px] text-base">{formatAmountOrigin(+dataModal?.stake)}</p>
              <div className="flex items-center gap-1">
                <img src="/images/staking/token-icon.png" alt="icon" className="w-6 h-6" />
                <p className="text-[#BDCADB] text-base">OPX</p>
              </div>
            </div>
          </div>

          <div className="flex sm:flex-col flex-row gap-3">
            <p className="text-sm text-[#BDCADB]">Earned</p>

            <div className="flex items-center gap-3">
              <p className="text-[#F0F6FF] min-w-[80px] text-base">{formatAmountOrigin(+dataModal?.earn)}</p>
              <div className="flex items-center gap-1">
                <img src="/images/staking/token-icon.png" alt="icon" className="w-6 h-6" />
                <p className="text-[#BDCADB] text-base">OPX</p>
              </div>
            </div>
          </div>
        </div>

        <div className="input-withdraw flex flex-col gap-3">
          <span className="label-input">You will {title}</span>
          <div className="flex content-input">
            <Input
              value={formatAmountOrigin(
                dataModal?.type === 'Withdraw' ? +dataModal?.stake + +dataModal?.earn : +dataModal?.earn,
              )}
              disabled
            />
            <div className="text">
              <img src="/images/logo.png" alt="" />
              <p className="text-base text-[#F2F8FF]">OPX</p>
            </div>
          </div>
          <div className="info-stake gap-3 flex flex-col">
            <div className="info-stake-item flex justify-between">
              <p>Minimum staking time:</p>
              <span>
                {moment
                  .duration(moment(dataModal?.endBlock * 1000).diff(moment(dataModal?.startBlock * 1000)))
                  .asDays()}{' '}
                days
              </span>
            </div>
            <div className="info-stake-item flex justify-between">
              <p>Stake date:</p>
              <span>{moment(dataModal?.startBlock * 1000).format('MMM DD, YYYY')}</span>
            </div>
            <div className="info-stake-item flex justify-between">
              <p>Last day to earn APY:</p>
              <span>{moment(dataModal?.endBlock * 1000).format('MMM DD, YYYY')}</span>
            </div>
            <div className="info-stake-item flex justify-between">
              <p>Rate day to earn APY:</p>
              <span>{((+dataModal?.earn / +dataModal?.stake) * 100).toFixed(4)}%</span>
            </div>
          </div>
          <Button className="btn-staking" onClick={() => onConfirm(dataModal)} loading={loading}>
            Confirm
          </Button>
        </div>
      </WrapperAccount>
    </ModalContent>
  )
}

export default ModalConfirm
