import styled from 'styled-components'

const WrapperStaking = styled.div`
  margin-top: 102px;
  width: 100%;
`

export const ModalConnectStyle = styled.div``

export const ModalContent = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  bottom: 12px;
  right: 12px;
  background-color: transparent;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    top: 24px;
    left: 24px;
    bottom: 24px;
    right: 24px;
  }

  .title {
    color: #fff;
    font-size: 20px;
    line-height: 1;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 24px;
    }
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #f1f1f1;
    margin-bottom: 24px;

    a {
      color: #3772ff;
    }
  }
  .position {
    color: #f0f6ff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 24px */
    text-transform: capitalize;
    display: flex;
    justify-content: center;
  }

  .body {
    width: 100%;
    gap: 44px;
    .body-item {
      width: 50%;
      color: #bdcadb;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 20px */
      letter-spacing: 0.4px;
      .body-item-header {
        margin-bottom: 16px;
      }
      .number-stake {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 20px */
        letter-spacing: -0.4px;
        display: flex;

        align-items: center;
      }
      span {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 20px;
        }
      }
      img {
        width: 24px;
        aspect-ratio: 1/1;

        ${({ theme }) => theme.mediaQueries.md} {
          width: 32px;
        }
      }
    }
  }
  .div-btn {
    display: flex;
    justify-content: center;
    font-weight: bold;
    gap: 16px;

    ${({ theme }) => theme.mediaQueries.md} {
      gap: 32px;
    }

    > button {
      position: relative;
      background: linear-gradient(to right, #00daf4, #5edc99, #bada6d, #ffc800, #ffaf00, #ff7800);
      padding: 1px;
      cursor: pointer;
      border-radius: 13px;
      border: none;
      width: 100%;

      ${({ theme }) => theme.mediaQueries.md} {
        max-width: 150px;
      }

      :disabled {
        opacity: 0.6;
        cursor: not-allowed;

        :hover {
          background: linear-gradient(to right, #00daf4, #5edc99, #bada6d, #ffc800, #ffaf00, #ff7800);
        }
      }

      div {
        padding: 8px;
        border-radius: 12px;
        width: fit-content;

        span {
          font-size: 16px;
          line-height: 1;
          font-weight: 600;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .input-amount-left {
      color: inherit;

      div {
        background-color: black;
        width: 100%;

        ${({ theme }) => theme.mediaQueries.md} {
          max-width: 150px;
        }

        span {
          background: linear-gradient(to right, #00daf4, #5edc99, #bada6d, #ffc800, #ffaf00, #ff7800);
        }
      }
    }
    .input-amount-right {
      color: #000;

      div {
        span {
          color: black;
        }
      }
    }
    .info-item {
      img {
        height: 32px;
        width: 32px;
      }
      .info-item-left {
        gap: 16px;
        align-items: center;
        p {
          color: #bdcadb;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 20px */
          letter-spacing: 0.4px;
        }
        span {
          color: #fff;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 24px */
          letter-spacing: -0.48px;
        }
      }
      .info-item-right {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
        align-items: center;
      }
    }
  }
  .input-withdraw {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    img {
      width: 24px;
      height: 24px;
    }
    .content-input {
      position: relative;

      .text {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        gap: 6px;
        right: 0;
        display: flex;
        align-items: center;
        gap: 6px;
        top: 50%;
        transform: translate(-18%, -50%);
      }
      input {
        color: #f0f6ff;
        font-size: 24px;
        font-weight: 700;
        line-height: 1;

        padding: 8px 80px 8px 16px;
        border-radius: 8px;
        border: 1px solid #03f1e366;
        background: rgba(0, 0, 0, 0.04);

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
    .info-stake {
      .info-stake-item {
        p {
          color: #fff;
          font-size: 14px;
          line-height: 1.5;
        }
        span {
          color: #03f1e3;
          text-align: right;
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }
    .btn-staking {
      padding: 10px;
      border-radius: 12px;
      width: 50%;
      border: 1px solid transparent;
      margin: 0 auto;

      :not(:disabled) {
        background: #03f1e3;
        color: #000;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .position {
      font-size: 16px;
    }
    .body {
      flex-wrap: wrap;
      gap: 12px;
      .body-item {
        width: 100%;
        font-size: 16px;
      }
    }
    .input-withdraw {
      width: 100%;
      .info-stake .info-stake-item {
        p,
        span {
          font-size: 14px;
        }
      }

      .content-input {
        position: relative;
        .text {
          span {
            font-size: 13px;
          }
        }
      }
    }
    .div-btn .info-item {
      .info-item-left {
        p,
        span {
          font-size: 16px;
        }
      }
      .info-item-right {
        font-size: 16px;
      }
    }
  }
`

export const WrapperAccount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .btn-logout {
    width: fit-content;
    margin: 0 auto;
  }

  .period-item {
    .div-btn.phase-2 {
      flex-direction: column;
      gap: 16px;

      ${({ theme }) => theme.mediaQueries.md} {
        gap: 32px;
      }
    }
  }
`

export default WrapperStaking
