import React from 'react'
import { Svg, SvgProps } from 'widgets'

const ModalBorderStaking: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="482" height="560" viewBox="0 0 482 560" fill="none" {...props}>
      <g filter="url(#filter0_b_22557_18077)">
        <path
          d="M0 24C0 10.7452 10.7452 0 24 0H421.525C428.138 0 434.458 2.72894 438.993 7.54252L475.469 46.2588C479.664 50.7117 482 56.5985 482 62.7163V535.5C482 548.755 471.255 559.5 458 559.5H24C10.7452 559.5 0 548.755 0 535.5L0 24Z"
          fill="url(#paint0_radial_22557_18077)"
        />
        <path
          d="M1 24C1 11.2974 11.2975 1 24 1H421.525C427.862 1 433.919 3.61524 438.265 8.22825L474.741 46.9445C478.761 51.2119 481 56.8534 481 62.7163V535.5C481 548.203 470.703 558.5 458 558.5H24C11.2975 558.5 1 548.203 1 535.5V24Z"
          stroke="url(#paint1_linear_22557_18077)"
          strokeOpacity="0.8"
          strokeWidth="2"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_22557_18077"
          x="-15"
          y="-15"
          width="512"
          height="589.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_22557_18077" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_22557_18077" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_22557_18077"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(574.449 707.938) rotate(-127.534) scale(892.741 1422.78)"
        >
          <stop />
          <stop offset="0.485796" stopColor="#060606" />
          <stop offset="1" stopColor="#1B1B1B" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_22557_18077"
          x1="241"
          y1="1.81243e-07"
          x2="233.941"
          y2="727.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default ModalBorderStaking
