/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core'
import { Modal } from 'antd'
import CloseModalButton from 'components/CloseModalButton'
import ModalBorderStaking from 'components/ModalConnect/ModalBorderStaking'
import { GAS_PRICE_DEFAULT } from 'config/constants'
import { useOpxContract, useStakingContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { setModalStaking } from 'state/modal/actions'
import { getTransactionReceiptMined } from 'utils'
import ModalConfirm from './ModalConfirm'
import { ModalConnectStyle } from './styled'

const ModalStaking = ({ getWalletBalance, fetchUserStakingInfo }) => {
  const { account } = useWeb3React()
  const { toastSuccess, toastError } = useToast()
  const dispatch = useDispatch()
  const { toggle, dataModal } = useSelector((state: AppState) => state.modal.modalStaking)

  const [loading, setLoading] = useState(false)

  const contractMethod = useOpxContract()

  const handleCancel = () => {
    setLoading(false)
    dispatch(setModalStaking({ toggle: false, dataModal: null }))
  }

  const handleConfirm = async (params) => {
    if (contractMethod && params && !loading && account) {
      setLoading(true)
      if (params?.type === 'Harvest') {
        try {
          const res = await contractMethod.withdraw(params?.poolId, {
            from: account,
            gasPrice: GAS_PRICE_DEFAULT,
          })
          if (res?.hash) {
            const receipt = await getTransactionReceiptMined(res?.hash, 500)
            if (receipt) {
              await getWalletBalance()
              await fetchUserStakingInfo()
              setLoading(false)
              handleCancel()
              toastSuccess('Successfully')
            }
          }
        } catch (error: any) {
          const errMsg = error?.message
          toastError(errMsg || 'Harvest failed!')
          setLoading(false)
          handleCancel()
        }
      }
      if (params?.type === 'Withdraw') {
        try {
          const res = await contractMethod.leaveStaking(params?.poolId, {
            from: account,
            gasPrice: GAS_PRICE_DEFAULT,
          })
          if (res?.hash) {
            const receipt = await getTransactionReceiptMined(res?.hash, 500)
            if (receipt) {
              await fetchUserStakingInfo()
              await getWalletBalance()
              setLoading(false)
              handleCancel()
              toastSuccess('Successfully')
            }
          }
        } catch (error: any) {
          const errMsg = error?.message
          toastError(errMsg || 'Withdraw failed!')
          setLoading(false)
          handleCancel()
        }
      }
    }
  }

  return (
    <ModalConnectStyle>
      <Modal
        key="modal-connect"
        open={toggle}
        centered
        width={480}
        className="modal-connect"
        footer={null}
        closeIcon={<CloseModalButton />}
        onCancel={handleCancel}
      >
        <ModalBorderStaking className="modal-border" />

        <ModalConfirm
          title={dataModal?.type}
          dataModal={{ ...dataModal?.stakingItem, type: dataModal?.type }}
          onConfirm={handleConfirm}
          loading={loading}
        />
      </Modal>
    </ModalConnectStyle>
  )
}

export default React.memo(ModalStaking)
